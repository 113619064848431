import React, { Component, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from 'axios';
import { Table, Form, FormGroup, Alert, Label, Button, ButtonGroup, Input, Row, Col, InputGroup, InputGroupAddon } from 'reactstrap';
import BaseConverterComponent from '../BaseConverterComponent';
import BaseComponent from '../BaseComponent';
import SEOComponent from '../SEOComponent';
import { Helmet } from "react-helmet";

export default class Base64Tool extends BaseComponent { 
    constructor(props) {
        super(props);       
    }

    componentDidMount = () => {
       
    }
    handleEncode = (decodeText, line76) => {
        console.log("encoding...", decodeText);
        let resp = "";
        try {
            resp = btoa(encodeURIComponent(decodeText).replace(/%([0-9A-F]{2})/g,
                function toSolidBytes(match, p1) {
                    return String.fromCharCode('0x' + p1);
                }));
        }
        catch (ex) {
            console.log(ex);
        }
        console.log("encoded text is ", resp);
        if (line76) {
            resp = resp.match(/.{1,76}/g);
            resp = resp.join("\r\n");
        }
        return resp;
    }
    handleDecode = (encodeText, line76) => {
        console.log("decoding...", encodeText);
        let resp = "";
        try {
            resp = decodeURIComponent(atob(encodeText).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
        }
        catch (ex) {
            console.log(ex);
        }
        console.log("decoded text is ", resp);
        return resp;
    }
    render() {
        return (<>
            <SEOComponent  title="Base 64 Encode / Decode" description="Base 64 Encode Tool, Base 64 Decode Tool" keywords="base64,decode,encode,tool,free" />
            <BaseConverterComponent
            decodeHeader="Text / Decoded" decodeButtonText="Decode"
            encodeHeader="Encoded" encodeButtonText="Encode" description="Simply use the form below"
                header="Base64 Encode / Decode" onDecode={this.handleDecode} onEncode={this.handleEncode} />            
            </>
        )
    }

}