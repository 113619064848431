import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
class SEOComponent extends Component {
    static propTypes = {
        description: PropTypes.string,
        title: PropTypes.string,
        keywords: PropTypes.string,
    }
    render() {
        const description = this.props.description ? this.props.description.replace(/<\/?[^>]+(>|$)/g, "") : null;
        const title = `SİTEGÖZLEM | v2 | ${this.props.title}`;
        return (
            <Helmet>
                <title>{title}</title>
                {description ? <meta name="description" content={description.slice(0, 150)} /> : null}
                {this.props.title ? <meta name="subject" content={this.props.title} /> : null}
                {this.props.title ? <meta name="og:title" content={title} /> : null}
                {this.props.image ? <meta name="og:image" content={this.props.image} /> : null}
                {this.props.keywords ? <meta name="keywords" content={this.props.keywords} /> : null}
                <meta name="og:site_name" content="Site Gözlem" />
                {description ? <meta name="og:description" content={description} /> : null}
            </Helmet>
        );
    }
}
export default SEOComponent;