import React, { Component, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from 'axios';
import { Table, Form, FormGroup, Alert, Label, Button, ButtonGroup, Input, Row, Col, InputGroup, InputGroupAddon } from 'reactstrap';
import BaseComponent from './BaseComponent';

export default class BaseConverterComponent extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            decodeText: '',
            encodeText: '',
            live: false,
            line76: false
        }
    }

    handleEncode = () => {
        console.log("*** over write ...");
        const resp = this.props.onEncode(this.state.decodeText, this.state.line76);
        this.setState({ encodeText: resp });
    }

    handleDecodeOfText = (e) => {
        this.setState({ encodeText: e.target.value }, () => {
            if (this.state.live) {
                this.handleDecode();
                console.log("handleDecodeOfText");
            }
        });
    }
    handleEncodeOfText = (e) => {
        this.setState({ decodeText: e.target.value }, () => {
            if (this.state.live) {
                this.handleEncode();
                console.log("handleEncodeOfText");
            }
        });
    }

    handleLiveMode = e => {
        this.setState({
            live: e.target.checked
        }, () => { this.handleEncode(); });
    }
    handleLineMode = e => {
        this.setState({
            line76: e.target.checked
        }, () => { this.handleEncode(); });
    }

    handleDecode = () => {
        console.log("*** over write ...");
        const resp = this.props.onDecode(this.state.encodeText, this.state.line76);
        this.setState({  decodeText: resp });
    }

    render() {
        return (
            <>
                <Row>
                    <Col>
                        <h1>{this.props.header}</h1>
                        <h4>{this.props.description}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="decodeTextArea" className="font-weight-bold">{this.props.decodeHeader}</Label>
                            <Input id="decodeTextArea" type="textarea" value={this.state.decodeText} rows={8} cols={50} onChange={this.handleEncodeOfText} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ButtonGroup>
                            {this.props.decodeButtonText && <Button onClick={this.handleDecode} >{this.props.decodeButtonText}</Button>}
                            {this.props.encodeButtonText && <Button onClick={this.handleEncode}>{this.props.encodeButtonText}</Button>}
                        </ButtonGroup>


                        
                        {this.props.liveMode!==false && <FormGroup check>
                            <Label check>
                                <Input type="checkbox" onChange={this.handleLiveMode} />
                                Live mode
                            </Label>
                        </FormGroup>
                        }

                        {this.props.ExtraToolBar && this.props.ExtraToolBar}

                        {this.props.LineMode!==false && 
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" onChange={this.handleLineMode} />
                                Split lines into 76 character wide chunks <i>(useful for MIME)</i>.
                            </Label>
                        </FormGroup>
                        }

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="encodeTextArea" className="font-weight-bold">{this.props.encodeHeader}</Label>
                            <Input type="textarea" id="encodeTextArea" value={this.state.encodeText} onChange={this.handleDecodeOfText} rows={8} cols={50} />
                        </FormGroup>
                    </Col>
                </Row>
            </>
        );
    }

}