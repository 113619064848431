import 'bootstrap/dist/css/bootstrap.css';
import './lumen.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
//import { polyfillLoader } from 'polyfill-io-feature-detection';
import { polyfill } from 'es6-promise';
import axiosInterceptorsSetup from './axios.js';
import { Provider } from 'react-redux'
import store from './store/index'

polyfill();
require('isomorphic-fetch');
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
axiosInterceptorsSetup();

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    window.store = store;

window.env = process.env.NODE_ENV;

console.log("ENV is", process.env.NODE_ENV);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={baseUrl}>
            <App />
        </BrowserRouter>
    </Provider>,
    rootElement);


//registerServiceWorker();

