import { createStore, compose, applyMiddleware } from 'redux';
import rootReducer from '../reducers';
import logger from 'redux-logger';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const store = createStore(
    rootReducer,
    composeEnhancer(applyMiddleware(logger))
);


export default store;