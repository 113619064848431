import { TOOL_REQUEST } from '../actions/tools';


const initialState = {
    request: ''
};

function toolsReducer(state = initialState, action) {
    switch (action.type) {
        case TOOL_REQUEST: {
            return { ...state, request: action.payload }
        }       
        default: return state;
    }
}
export default toolsReducer;