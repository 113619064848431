import React, { Component, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from 'axios';
import { Table, Form, FormGroup, Alert, Label, Button, ButtonGroup, Input, Row, Col, InputGroup, InputGroupAddon } from 'reactstrap';
import BaseConverterComponent from '../BaseConverterComponent';
import BaseComponent from '../BaseComponent';
import SEOComponent from '../SEOComponent';
import { Helmet } from "react-helmet";
import * as moment from 'moment';
//import Terser from 'terser';

export default class UnixTimeTool extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
           now: '',
           t: '', //user input timestamp

           //user intput time date values
           yyyy:moment().year(),
           mm:moment().format("MM"),
           dd:moment().format("DD"),
           hh:moment().format("HH"),
           mn:moment().format("mm"),
           ss:moment().format("ss"),
           tz:'1',
           am:'',

           //result timestamp
           rs:'',
           rst:''
        }
    }

    ToLocal = (t) => {
        return moment.unix(t/1000).utc(true).format("LLLL");
    }
    ToGMT = (t) => {
        return moment.unix(t/1000).utc(false).format("LLLL");
    }
    componentWillUnmount = () => {
        clearInterval(this.timer);
        this.setState({now : new Date().getTime(), t: new Date().getTime()});
    }
    componentDidMount = () => {
        this.setState({ now: new Date().getTime() });
        this.timer = setInterval(() => { this.setState({ now: new Date().getTime() }); }, 1000);
        this.calc();
    }
    handleToHumanDate = (decodeText) => {
      
    }
    handleToEpochTime = (encodeText) => {


    }
    calc = () => {
        let str = this.state.yyyy+"-"+this.state.mm+"-"+this.state.dd+" "+this.state.hh+":"+this.state.mn+":"+this.state.ss;            
        if(this.state.tz=="1")
            this.setState({ 
                rs: moment.utc(str).unix(), 
                rst: moment(str).utc().format("YYYY-MM-DD HH:mm:ss"),
                rssmunix: moment(str).utc().startOf('month').unix(), 
                rssm: moment(str).utc().startOf('month').format("YYYY-MM-DD HH:mm:ss"),
                rsemunix: moment(str).utc().endOf('month').unix(), 
                rsem: moment(str).utc().endOf('month').format("YYYY-MM-DD HH:mm:ss")
            });
        else 
            this.setState({ 
                rs: moment(str).unix(), 
                rst: moment(str).format("YYYY-MM-DD HH:mm:ss"),
                rssmunix: moment(str).startOf('month').unix(), 
                rssm: moment(str).startOf('month').format("YYYY-MM-DD HH:mm:ss"),
                rsemunix: moment(str).endOf('month').unix(), 
                rsem: moment(str).endOf('month').format("YYYY-MM-DD HH:mm:ss")

            });
    }

    handleChange2 = (event) => {        
        this.setState({ [event.target.name] : event.target.value  }, () => {
            this.calc();            
        });

        return;
    }
    handleChange = (event) => {
        if(!isNaN(parseFloat(event.target.value)))
            this.setState({t: event.target.value});
        else 
            this.setState({t: NaN});
    }
    handleNowClick = (event) => {
        this.setState({t : this.state.now});
    }
   
    render() {
        return (<>
            <SEOComponent title="Epoch Converter - Unix Timestamp Converter" description="Epoch Converter,  Unix Timestamp Converter" keywords="epoch, unix, timestamp, time, january 1 1970, posix, convert, conversion, converter, calculator, calendar" />
            <div className="row">
                <div className="col-md-12">
                    <p>The current Unix epoch time is <span className="btn btn-primary" onClick={this.handleNowClick}>{this.state.now}</span></p>
                    <h2>Convert epoch to human-readable date and vice versa</h2>
                </div>
                <div className="col-md-12">
                    <div className="input-group mb-3">
                        <input type="text" size="16" onChange={this.handleChange} maxLength="20" id="ecinput" className="form-control" name="TimeStamp" value={this.state.t} spellCheck="false" autoComplete="off" aria-label="Timestamp"/>
                        <div className="input-group-append">
                            <button type="submit" className="btn btn-primary" onClick={this.handleToHumanDate} >Timestamp to Human date</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div>Supports Unix timestamps in seconds, milliseconds, microseconds and nanoseconds.</div>
                    <div>Epoch timestamp: {(this.state.t/1000).toFixed(0)}</div>
                    <div>Timestamp in milliseconds: {(this.state.t/1).toFixed(0)}</div>
                    <div>Date and time (GMT): {this.ToGMT(this.state.t)}</div>
                    <div>Date and time (Your time zone): {this.ToLocal(this.state.t)}</div>
                </div>                
                <div className="col-md-12">
                <hr/>
                        <table className="tool hf-table date-input">
                            <tbody>
                                <tr>
                                    <td>Year</td>
                                    <td>Month</td>
                                    <td>Day</td>
                                    <td>Hour</td>
                                    <td>Minute</td><td>Second</td></tr>
                                <tr>
                                    <td id="hf-d1">
                                        <input value={this.state.yyyy} onChange={this.handleChange2} className="form-control dateform year" autoComplete="off" type="text" size="4" maxLength="4"  name="yyyy" aria-label="Year" />                                    
                                    </td>
                                    <td id="hf-d2">
                                        <input value={this.state.mm} onChange={this.handleChange2} className="form-control dateform month" autoComplete="off" type="text" size="2" maxLength="2" name="mm" aria-label="Month"/>
                                    </td>
                                    <td id="hf-d3">
                                        <input value={this.state.dd} onChange={this.handleChange2} className="form-control dateform day" autoComplete="off" type="text" size="2" maxLength="2"  name="dd" aria-label="Day" />
                                    </td>
                                    <td>
                                        <input value={this.state.hh} onChange={this.handleChange2} className="form-control dateform" type="text" autoComplete="off" size="2" maxLength="2"  name="hh" aria-label="Hours" />
                                    </td>
                                    <td>
                                        <input value={this.state.mn} onChange={this.handleChange2} className="form-control dateform" type="text" autoComplete="off" size="2" maxLength="2"  name="mn" aria-label="Minutes" />
                                    </td>
                                    <td>
                                        <input value={this.state.ss} onChange={this.handleChange2} className="form-control dateform" type="text" autoComplete="off" size="2" maxLength="2"  name="ss" aria-label="Seconds" />
                                        <span className="ampm" style={{display:'none'}}>
                                            <select value={this.state.am} onChange={this.handleChange2} name="am" aria-label="AM or PM">
                                                <option value="am">AM</option>
                                                <option value="pm">PM</option>
                                            </select>
                                        </span>                                        
                                    </td>
                                    <td>
                                        <select value={this.state.tz} onChange={this.handleChange2} name="tz" className="form-control pref-tz" aria-label="Timezone">
                                            <option value="1" >GMT</option><option value="2">Local time</option></select>
                                    </td>
                                        <td><button type="submit" className="btn btn-primary">Human date to Timestamp</button></td>
                                    </tr>
                                </tbody>                                    
                            </table>
                            <table className="infotable table-tool">
                                <thead>
                                    <tr><th></th><th>Epoch</th><th>Date and time</th></tr>
                                </thead>
                                <tbody>
                                    <tr><td></td><td>{this.state.rs}</td><td>{this.state.rst}</td></tr>
                                    <tr><td>Start of month:&nbsp;</td><td>{this.state.rssmunix}</td><td>{this.state.rssm}</td></tr>
                                    <tr><td>End of month:&nbsp;</td><td>{this.state.rsemunix}</td><td>{this.state.rsem}</td></tr>
                                </tbody>
                            </table>
                            <hr/>
                    </div>                    
                    <div className="col-md-12">
                        <h2>What is epoch time?</h2>
                        <p>The <b>Unix epoch</b> (or <b>Unix time</b> or <b>POSIX time</b> or <b>Unix timestamp</b>) is the number of seconds that have elapsed since January 1, 1970 (midnight UTC/GMT), not counting leap seconds (in ISO 8601: 1970-01-01T00:00:00Z).
                        Literally speaking the epoch is Unix time 0 (midnight 1/1/1970), but 'epoch' is often used as a synonym for Unix time.
                        Some systems store epoch dates as a signed 32-bit integer, which might cause problems on January 19, 2038 (known as the Year 2038 problem or Y2038).
                        The converter on this page converts timestamps in seconds (10-digit), milliseconds (13-digit) and microseconds (16-digit) to readable dates.</p>
                        <hr/>
                    </div>
                    <div className="col-md-12">
                    <table className="infotable"><thead>
                        <tr><th>Human-readable time&nbsp;</th><th>Seconds</th></tr>
                        </thead><tbody>
                        <tr><td>1 hour</td><td>3600 seconds</td></tr>
                        <tr><td>1 day</td><td>86400 seconds</td></tr>
                        <tr><td>1 week</td><td>604800 seconds</td></tr>
                        <tr><td>1 month (30.44 days)&nbsp;</td><td>2629743 seconds</td></tr>
                        <tr><td>1 year (365.24 days)&nbsp;</td><td>&nbsp;31556926 seconds</td></tr>
                        </tbody></table>
                        <hr/>
                    </div>
                    <div className="col-md-12">
                    <h2 id="code">How to get the current epoch time in ...</h2>
                    <table className="syntaxtable" >
                        <tbody>
                            <tr><td className="proglang">PHP</td><td className="progcode"><code>time()</code></td></tr>
                        <tr><td className="proglang">Python</td><td className="progcode"><code>import time; time.time()</code> </td></tr>
                        <tr><td className="proglang">Ruby</td><td className="progcode"><code>Time.now</code> (or <code>Time.new</code>). To display the epoch: <code>Time.now.to_i</code></td></tr>
                        <tr><td className="proglang">Perl</td><td className="progcode"><code>time</code></td></tr>
                        <tr><td className="proglang">Java</td><td className="progcode"><code>long epoch = System.currentTimeMillis()/1000;</code></td></tr>
                        <tr><td className="proglang">C#</td><td className="progcode"><code>DateTimeOffset.Now.ToUnixTimeSeconds()</code> (.NET Framework 4.6+/.NET Core), older versions: <code>var epoch = (DateTime.UtcNow - new DateTime(1970, 1, 1, 0, 0, 0, DateTimeKind.Utc)).TotalSeconds;</code></td></tr>
                        <tr><td className="proglang">Objective-C</td><td className="progcode"><code>[[NSDate date] timeIntervalSince1970];</code> (returns double) or <code>NSString *currentTimestamp = [NSString stringWithFormat:@"%f", [[NSDate date] timeIntervalSince1970]];</code></td></tr>
                        <tr><td className="proglang">C++11</td><td className="progcode"><code>double now = std::chrono::duration_cast&lt;std::chrono::seconds&gt;(std::chrono::system_clock::now().time_since_epoch()).count();</code></td></tr>
                        <tr><td className="proglang">Lua</td><td className="progcode"><code>epoch = os.time([date])</code></td></tr>
                        <tr><td className="proglang">VBScript/ASP</td><td className="progcode"><code>DateDiff("s", "01/01/1970 00:00:00", Now())</code></td></tr>
                        <tr><td className="proglang">AutoIT</td><td className="progcode"><code>_DateDiff('s', "1970/01/01 00:00:00", _NowCalc())</code></td></tr>
                        <tr><td className="proglang" title="Embarcadero Delphi">Delphi</td><td className="progcode"><code>Epoch := DateTimetoUnix(Now);</code> Tested in Delphi 2010.</td></tr>
                        <tr><td className="proglang">R</td><td className="progcode"><code>as.numeric(Sys.time())</code></td></tr>
                        <tr><td className="proglang">Erlang/OTP</td><td className="progcode"><code>erlang:system_time(seconds).</code> (version 18+), older versions: <code>calendar:datetime_to_gregorian_seconds(calendar:universal_time())-719528*24*3600.</code></td></tr>
                        <tr><td className="proglang">MySQL</td><td className="progcode"><code>SELECT unix_timestamp(now())</code></td></tr>
                        <tr><td className="proglang">PostgreSQL</td><td className="progcode"><code>SELECT extract(epoch FROM now());</code></td></tr>
                        <tr><td className="proglang">SQLite</td><td className="progcode"><code>SELECT strftime('%s', 'now');</code></td></tr>
                        <tr><td className="proglang">Oracle PL/SQL</td><td className="progcode"><code>SELECT (CAST(SYS_EXTRACT_UTC(SYSTIMESTAMP) AS DATE) - TO_DATE('01/01/1970','DD/MM/YYYY')) * 24 * 60 * 60 FROM DUAL;</code></td></tr>
                        <tr><td className="proglang">SQL Server</td><td className="progcode"><code>SELECT DATEDIFF(s, '1970-01-01 00:00:00', GETUTCDATE())</code></td></tr>
                        <tr><td className="proglang">IBM Informix</td><td className="progcode"><code>SELECT dbinfo('utc_current') FROM sysmaster:sysdual;</code></td></tr>
                        <tr><td className="proglang">JavaScript</td><td className="progcode"><code>Math.floor(new Date().getTime()/1000.0)</code> The getTime method returns the time in milliseconds.</td></tr>
                        <tr><td className="proglang">Visual FoxPro</td><td className="progcode"><code>DATETIME() - ^1970/01/01 00:00:00</code> Warning: time zones not handled correctly</td></tr>
                        <tr><td className="proglang">Go</td><td className="progcode"><code>time.Now().Unix()</code> </td></tr>
                        <tr><td className="proglang">Adobe ColdFusion</td><td className="progcode"><code>&lt;cfset epochTime = left(getTickcount(), 10)&gt;</code></td></tr>
                        <tr><td className="proglang">Tcl/Tk</td><td className="progcode"><code>clock seconds</code></td></tr>
                        <tr><td className="proglang">Unix/Linux Shell</td><td className="progcode"><code>date +%s</code></td></tr>
                        <tr><td className="proglang">Solaris</td><td className="progcode"><code>/usr/bin/nawk 'BEGIN print srand()'</code> Solaris doesn't support <i>date +%s</i>, but the default seed value for nawk's random-number generator is the number of seconds since the epoch.</td></tr>
                        <tr><td className="proglang">PowerShell</td><td className="progcode"><code>[int][double]::Parse((Get-Date (get-date).touniversaltime() -UFormat %s))</code></td></tr>
                        <tr><td className="proglang">Other OS's </td><td className="progcode">Command line: <code>perl -e "print time"</code> (If Perl is installed on your system)</td></tr>
                        </tbody></table>    
                        </div>            
            </div>
        </>
        )
    }

}