import React, { Component, useState } from 'react';
import ReactGA from 'react-ga';


export default class BaseComponent extends Component {

    constructor(props) {
        super(props);

        ReactGA.initialize('UA-941275-26');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }
}