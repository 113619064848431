import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;   

  constructor (props) {
    super(props);

      
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
    }


    renderAds() {

        return (<ins />);

        const adtest = process.env.NODE_ENV === 'development' ? "on" : "";
        if (window.env == "development") {
            return (<ins />);
        }
        else {
            return (
                <>
                    <div className="titlebar-container">    
                        <ins className="adsbygoogle"
                            style={{ display: 'block' }}
                            data-ad-client="ca-pub-3594574627186253"
                            data-ad-slot="6552002826"
                            data-adtest={adtest}
                            data-ad-format="auto"
                            data-full-width-responsive="true"></ins>
                        <script>
                            (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                    </div>
                </>
            );
        }
    }

    //box-shadow mb-3
    render() {

        

    return (
        <>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom ">
          <Container>
            <NavbarBrand tag={Link} to="/">SİTEGÖZLEM</NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link}  to="/">Anasayfa</NavLink>
                            </NavItem>
                            
                
                            <NavItem>
                                <NavLink tag={Link} to="/sss">SSS</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/support">Destek</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/about-us">Hakkımızda</NavLink>
                            </NavItem>
                <LoginMenu>
                </LoginMenu>
              </ul>
            </Collapse>
          </Container>
            </Navbar>

                                       
                {this.renderAds()}
           
                

            <div className="nav-scroller bg-white shadow-sm">
                <nav className="nav nav-underline">                                       
                    <a className="nav-link" >
                        Ücretsiz İzleme / Free Monitoring
                        <span className="badge badge-pill bg-light align-text-bottom">yakında</span>
                    </a>                    
                    <Link to="/ssl-check" className="nav-link ">SSL Kontrol / SSL Check</Link>
                    <Link to="/emailcheck" className="nav-link" >Email Validation / Mail Doğrulama</Link>                    
                    <Link to="/mxlookup" className="nav-link" >MX Sorgu / MX Lookup</Link>                    
                    <Link to="/dnslookup" className="nav-link" >DNS Sorgu / DNS Lookup</Link>                    
                    <Link to="/ping" className="nav-link ">Ping</Link>
                    <Link to="/ipinfo" className="nav-link ">IP Adresim / My Ip</Link>
                    <Link to="/jsminfiy" className="nav-link ">Javascript Minifier</Link>
                    <Link to="/jsoneditor" className="nav-link ">JSON Editor</Link>
                    <Link to="/decimalhexconverter" className="nav-link ">Decimal Hex Converter</Link>                    
                    <Link to="/unixtimeconverter" className="nav-link ">Unix Time Converter</Link>                    
                    <Link to="/base64decodeencode" className="nav-link ">Base64 Encode / Decode</Link>
                    <Link to="/urldecodeencode" className="nav-link ">Url Encode / Decode</Link>
                    
                    
                    
                </nav>
            </div>
      </>
    );
  }
}


/*
 
 <NavItem>
                  <NavLink tag={Link}  to="/counter">Counter</NavLink>
                            </NavItem
                            >
                <NavItem>
                  <NavLink tag={Link}  to="/fetch-data">Fetch data</NavLink>
                            </NavItem>

 
 */