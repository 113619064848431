import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BaseComponent from './BaseComponent';

export class Oops extends BaseComponent {
    static displayName = "Oops";

    render() {
        return (
            <div>
                <h1>Oops!</h1>
                <p>aradığınız sayfa elimizde mevcut değil :)</p>
            </div>
        );
    }
}
