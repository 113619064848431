import React, { Component, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from 'axios';
import { Table, Form, FormGroup, Alert, Label, Button, ButtonGroup, Input, Row, Col, InputGroup, InputGroupAddon } from 'reactstrap';
import BaseConverterComponent from '../BaseConverterComponent';
import BaseComponent from '../BaseComponent';
import SEOComponent from '../SEOComponent'
import { Helmet } from "react-helmet";

export default class UrlDecodeEncodeTool extends BaseComponent {
    constructor(props) {
        super(props);
    }
    handleEncode = (decodeText, line76) => {
        console.log("encoding...", decodeText);
        let resp = encodeURIComponent(decodeText);
        console.log("encoded text is ", resp);
        if (line76) {
            resp = resp.match(/.{1,76}/g);
            resp = resp.join("\r\n");
        }
        return resp;
    }
    handleDecode = (encodeText, line76) => {
        console.log("decoding...", encodeText);
        let resp = "";
        try {
            resp = decodeURIComponent(encodeText);
        }
        catch (ex) {
            console.log(ex);
        }
        console.log("decoded text is ", resp);
        return resp;
    }
    render() {
        return (<><BaseConverterComponent decodeHeader="Url / Decoded" decodeButtonText="Url Decode" encodeHeader="Url Encoded" encodeButtonText="Url Encode"
            description="Simply use the form below" header="Url Encode / Decode" onDecode={this.handleDecode} onEncode={this.handleEncode} />
            <SEOComponent  title="URL Encode / Decode" description="URL Encode Tool,URL Decode Tool" keywords="url,decode,encode,tool,free" /></>
        )
    }

}