import axios from "axios";
import authService from './components/api-authorization/AuthorizeService';
import { ApplicationPaths, ApplicationName } from './components/api-authorization/ApiAuthorizationConstants';
import Cookies from 'js-cookie';

; (async () => {
    const forgery = ApplicationPaths.AntiforgeryPath;
    const token = await authService.getAccessToken();
    const headers = {};
    if (token)
        headers["Authorization"] = `Bearer ${token}`;

    await fetch(forgery, { headers });
})();


const axiosInterceptorsSetup = () => {
    console.log("axiosInterceptorsSetup called...");        
    axios.interceptors.request.use(async (config) => {
        const token = await authService.getAccessToken();
        if (token)
            config.headers['Authorization'] = 'Bearer ' + token;
        const xsrf = Cookies.get('XSRF-REQUEST-TOKEN');        
        if (xsrf)
            config.headers["X-XSRF-TOKEN"] = xsrf;
        else {
            const forgery = ApplicationPaths.AntiforgeryPath;
            await fetch(forgery);
            config.headers["X-XSRF-TOKEN"] = Cookies.get('XSRF-REQUEST-TOKEN');
        }
        return config;
    },
        error => {
            Promise.reject(error);
        }
    );

    /*
    axios.interceptors.response.use((response) => {
        return response
    }, function (error) {
        const originalRequest = error.config;
    
        if (error.response.status === 401 && originalRequest.url ===
            'http://13.232.130.60:8081/v1/auth/token) {
           router.push('/login');
        return Promise.reject(error);
    }
    
       if (error.response.status === 401 && !originalRequest._retry) {
    
        originalRequest._retry = true;
        const refreshToken = localStorageService.getRefreshToken();
        return axios.post('/auth/token',
            {
                "refresh_token": refreshToken
            })
            .then(res => {
                if (res.status === 201) {
                    localStorageService.setToken(res.data);
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();
                    return axios(originalRequest);
                }
            })
    }
    return Promise.reject(error);
    });
    */
}

export default axiosInterceptorsSetup;