import React, { Component, Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Oops } from './components/Oops';
import Base64Tool from './components/base64';
import JSMinifierTool from './components/jsminifier';
import UnixTimeTool from './components/unixtime/';
import DecimalHexTool from './components/decimalhex/';
import UrlDecodeEncodeTool from './components/urldecodeencode';

import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { Spinner } from 'reactstrap';
import { Helmet } from "react-helmet";
import './custom.css'



const HomeLazy = lazy(() => import('./components/Home'));
const ConnectedMxLookupLazy = lazy(() => import('./components/mxlookup'));
const ConnectedEmailCheckerLazy = lazy(() => import('./components/emailchecker'));
const ConnectedDnsLookupLazy = lazy(() => import('./components/dnslookup'));
const ConnectedIpInfoLazy = lazy(() => import('./components/ipinfo'));
const ConnectedPingLazy = lazy(() => import('./components/ping'));
const ConnectedSslCheckLazy = lazy(() => import('./components/ssl-check'));

const ConnectedJSONEditorToolLazy = lazy(() => import('./components/jsoneditor'));

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>SİTEGÖZLEM | v2</title>
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://sitegozlem.com" />
            </Helmet>
            <Suspense fallback={<div className="text-center" style={{marginTop:'25vh'}}><Spinner size="sm"  type="grow" style={{ color:'#0366d6', width: '25vh', height: '25vh' }} /></div>}>
                <Switch>
                    <Route exact path='/' component={HomeLazy} />            
                    <Route path='/mxlookup/:host?' component={ConnectedMxLookupLazy} />
                    <Route path='/emailcheck/:emailaddress?' component={ConnectedEmailCheckerLazy} />                    
                    <Route path='/dnslookup/:host?/:typ?' component={ConnectedDnsLookupLazy} />
                    <Route path='/ipinfo' component={ConnectedIpInfoLazy} />
                    <Route path='/base64decodeencode' component={Base64Tool} />
                    <Route path='/jsminfiy' component={JSMinifierTool} />                   
                    <Route path='/jsoneditor' component={ConnectedJSONEditorToolLazy} />            
                    <Route path='/unixtimeconverter' component={UnixTimeTool} />                    
                    <Route path='/urldecodeencode' component={UrlDecodeEncodeTool} />
                    <Route path='/decimalhexconverter' component={DecimalHexTool} />                    
                    <Route path='/ping/:host?' component={ConnectedPingLazy} />IpInfo
                    <Route path='/ssl-check/:host?' component={ConnectedSslCheckLazy} />                                    
                    <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                    <Route path="*" component={Oops} />
                </Switch>
            </Suspense>
      </Layout>
    );
  }
}
