import React, { Component, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from 'axios';
import { Table, Form, FormGroup, Alert, Label, Button, ButtonGroup, Input, Row, Col, InputGroup, InputGroupAddon } from 'reactstrap';
import BaseConverterComponent from '../BaseConverterComponent';
import BaseComponent from '../BaseComponent';
import SEOComponent from '../SEOComponent';
import { Helmet } from "react-helmet";

//import Terser from 'terser';

export default class JSMinifierTool extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {            
            compress: false,
            mangle: false
        }
    }

    componentDidMount = () => {

    }
    handleEncode = (decodeText, line76) => {

        /*global Terser */
        var result =  Terser.minify(decodeText,  { compress: this.state.compress, mangle: this.state.mangle });
        console.log("handleEncode","decodeText",decodeText, "result",result);
        console.log(result.error); // runtime error, or `undefined` if no error
        console.log(result.code);  // minified output: function add(n,d){return n+d}
        return result.code;
    }
    handleDecode = (encodeText, line76) => {               
        
        
    }
    handleMangleMode = (e) => {
        this.setState({
            mangle: e.target.checked
        }, () => {  });
    }

    handleCompressMode = (e) => {
        this.setState({
            compress: e.target.checked
        }, () => {  });
    }

    ExtraItems = () => {

        return(<>
        <FormGroup check>
            <Label check>
                <Input type="checkbox" onChange={this.handleCompressMode} />
                Compress
            </Label>
        </FormGroup>
        <FormGroup check>
            <Label check>
                <Input type="checkbox" onChange={this.handleMangleMode} />
                Mangle
            </Label>
        </FormGroup>
        </>
        )

    }

    render() {
        return (<>
            <SEOComponent title="Javascript / JSON Minifier" description="Javascript, JSON Minifier Tool" keywords="javascript,js,json,minifier,tool,free" />
            <BaseConverterComponent
                decodeHeader="Javascript /JSON Input" decodeButtonText="" liveMode={false} LineMode={false}
                encodeHeader="" encodeButtonText="Minify" description="Simply use the form below"
                ExtraToolBar={this.ExtraItems()}
                header="Javascript Minifier" onDecode={this.handleDecode} onEncode={this.handleEncode}>

            </BaseConverterComponent>
        </>
        )
    }

}